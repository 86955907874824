// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-feeds-jsx": () => import("./../../../src/pages/feeds.jsx" /* webpackChunkName: "component---src-pages-feeds-jsx" */),
  "component---src-pages-tech-books-jsx": () => import("./../../../src/pages/tech-books.jsx" /* webpackChunkName: "component---src-pages-tech-books-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-feed-jsx": () => import("./../../../src/templates/Feed.jsx" /* webpackChunkName: "component---src-templates-feed-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */)
}

